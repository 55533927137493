<template>
	<span
		v-if="iconBadge"
		:class="iconClasses"
		pill
		:style="{ height: getSize.height, width: getSize.width }"
	>
		<i v-if="iconBadge" class="icon-badge" :class="props.icon"></i>
		<FontAwesomeIcon :size="getSize.size" :icon="getStyle(props.icon)" />
	</span>
	<FontAwesomeIcon
		v-else
		:class="iconClasses"
		:size="getSize.size"
		:icon="getStyle(props.icon)"
	/>
</template>

<script setup>
import { MDBBadge } from "mdb-vue-ui-kit"
import { defineProps, computed } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { lookupIcon } from "@/Composables/useAwesomeIcons"
const props = defineProps({
	icon: {
		type: String,
		required: true,
	},
	size: {
		type: String,
		default: "md",
	},
	color: {
		type: String,
		default: "primary",
	},
	options: {
		type: Object,
		default: () => ({}),
	},
	badgeOutline: {
		type: Boolean,
		default: true,
	},
})
const getStyle = (icon) => {
	const options = icon.split(".")
	if (options.length > 1) {
		return lookupIcon(options[1], options[0])
	} else {
		return lookupIcon(options[0], "fas")
	}
}

const getSize = computed(() => {
	let useSize = props.size
	if (props.options.size) {
		useSize = props.options.size
	}
	if (useSize == "md") {
		useSize = "sm"
	}
	let size = 40
	switch (useSize) {
		case "xs":
			size = 20
			break
		case "sm":
			size = 30
			break
		case "md":
			size = 40
			break
		case "lg":
			size = 50
			break
		case "xl":
			size = 60
			break
		default:
			size = 40
			break
	}
	return {
		size: useSize,
		width: size + "px",
		height: size + "px",
	}
})
const iconBadge = computed(() => {
	if (props.options.badge) {
		return true
	} else {
		return false
	}
})
const iconClasses = computed(() => {
	let classList = []
	if (props.options.badge) {
		classList = classList.concat([
			"global-icon-badge",
			"bg-transparent",
			"justify-content-center",
			"align-items-center",
			"d-flex",
		])
		if (props.color) {
			classList.push("text-" + props.color)
		} else {
			classList.push("text-primary")
		}
	}

	if (props.options.badge && props.badgeOutline) {
		classList = classList.concat(["border", "border-primary", "rounded-circle"])
	}
	if (props.options.classList) {
		if (typeof props.options.classList == Array) {
			classList = classList.concat(props.options.classList)
		} else {
			classList.push(props.options.classList)
		}
	} else {
		classList.push("me-2")
	}
	return classList
})
</script>

<style scoped>
i.icon-badge {
	font-size: 1.15em;
}
</style>
